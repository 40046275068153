<template>
<body class="page basicpage light-theme">

    <div id="oc-wrapper">

        <header class="header-v2 header-v2-cn" id="header-v2" style="display: block;">

            <Head></Head>
        </header>

        <section id="oc-container" class="oc-container-v2">

            <!-- <div style="height: 48px;">
                <div vue-site="oppo_navbar_V2" data-theme="light" data-buymode="link" class="oppo-navbar oppo-navbar-v2 is-start header-nofixed" style="display: none;">
                    <nav id="navbar">
                        <div class="navbar-container navbar-pc">
                            <div class="navbar-left">
                                <div class="navbar-product_tab">
                                    <ul class="product_tab-wrap">
                                        <li class="active">
                                            <a target="_self" class="product_tab-btn op-trk-event">{{  info.name }}</a></li>
                                    </ul>
                                </div>
                                <div class="navbar-anchor_point">
                                    <ul class="anchor_point-wrap" style="position: relative;">
                                        <li data-jumpid="#section-kv" class="anchor_point-item active"><a href="#section-kv" tm-event_name="click_navigation" tm-module="secondary navigation" tm-level="none" tm-nav_group="Content" tm-nav_name="概览" class="op-trk-event">概览</a></li>
                                        <li data-jumpid="#anchor-design" class="anchor_point-item"><a href="#anchor-design" tm-event_name="click_navigation" tm-module="secondary navigation" tm-level="none" tm-nav_group="Content" tm-nav_name="设计" class="op-trk-event">参数</a></li>
                                        <div class="underline" style="height: 2px; left: 0px; width: 27px; bottom: -1px; position: absolute; background: rgb(46, 200, 78); transition: all 0.3s ease 0s;"></div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div class="navbar_channel-popup hidden">
                        <div v-html="info.details"></div>
                    </div>
                </div>
            </div>


            <div>

            </div> -->

            <div class="root responsivegrid">

                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                    <div class="responsivegrid aem-GridColumn aem-GridColumn--default--12">

                        <div class="aem-Grid aem-Grid--12 aem-Grid--default--12 ">

                            <div class="customhtmlcomponent aem-GridColumn aem-GridColumn--default--12">

                                <div class="custom-html-component">

                                

                                    <div class="zn--wrapper bg-white">
                                        <div class="content" v-html="info" style="text-align: center;display: flex;flex-wrap: wrap;align-content: space-around;flex-direction: column;"></div>
                                        <!-- <div class="w-screen relative overflow-hidden" id="section-kv" ani-kv="" section-leave="" style="width:100vw;height:100vh" data-gtm-view="Part1: KV">
                                            <div class="relative z-2 flex items-center w-1312rpx m-auto h-full w-328ex w-[720apx] justify-start">
                                                <div class="desc-content relative text-black pc:mt-[-3.5vh] mo:h-full mo:w-full pad:w-max text-opacity-95">
                                                    <div class="text-box fade-in-kv w-328ex m-auto pad:w-max mo:pt-64px pad:pb-43px show" style="opacity: 1;">
                                                        <div class="font-32 font-24m font-m">OPPO Find X6 Pro</div>
                                                        <div class="font-68 font-48m mt-12px font-b">不分昼夜</div>
                                                        <div class="font-68 font-48m font-b">无论远近</div><img class="mt-24px mo:mt-20px pc:h-15px mo:h-11px pad:h-11px object-cover loaded" data-src="//image.oppo.com/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hass-1.svg" data-src-1440="//image.oppo.com/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hass-1.svg" src="//image.oppo.com/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hass-1.svg" data-ll-status="loaded"><a class="btn learn-more font-16 font-14m font-b mt-32px mo:mt-24px items-center bg-black text-white text-opacity-95" data-event="click"><img class="w-20px h-20px mr-2px mt-[-1px] loaded" data-src="//image.oppo.com/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-player-1.svg" alt="" data-src-1440="//image.oppo.com/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-player-1.svg" src="//image.oppo.com/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-player-1.svg" data-ll-status="loaded"><span>点击播放</span></a>
                                                    </div>
                                                    <div class="w-0vw self-start pc:absolute top-0 mo:mt-35px left-[100%] pl-200rpx"><img class="w-1321rpx h-663rpx pad:w-888px pad:h-446px kv-phone object-cover phone fade-in-kv show loaded" alt="" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-phone-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-phone-d7111.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-phone_mo-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-phone-1.png.webp" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-phone-1.png.webp" data-ll-status="loaded"></div>
                                                </div>
                                            </div>
                                            <div class="kv-bg w-100vw h-full object-cover absolute z-1 top-0 left-0"></div>
                                        </div>
                                        <div class="mo-hidden">
                                            <div class="g--popup-user" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/wukong_kv.mp4?v=230321" data-class="kv-popup" data-trigger="#section-kv .learn-more" data-label="Every Emotion, in Portrait" data-poster="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/videos-kv_pc_1440_poster-1.jpg.webp" popup-player="" data-version="1"></div>
                                        </div>

                                        <div class="w-full relative bg-black text-white text-opacity-95 z-2 overflow-hidden" id="section-highlight" ani-ksp="" style="--ani-duration:0.5s;">
                                            <div class="ksp-part elegant-black w-full">
                                                <div class="container w-full bg-black overflow-hidden" section-leave-test="">
                                                    <div class="light-wrapper w-1316rpx m-auto relative pc:flex pad:flex pad:flex-wrap w-[720apx] justify-between pt-120rpx pb-100rpx w-328ex py-80px h-700rpx" data-gtm-view="Part2: Highlights"><a class="light-box h-480rpx w-328ex h-260ex pad:h-[240apx] relative mo:mb-8px pad:mb-4px w-[358apx] cursor-pointer block overflow-hidden" data-gtm-click="Highlights" data-gtm-label="超光影三主摄" href="#section-camera">
                                                            <div class="w-full h-full relative z-1">
                                                                <div class="w-full h-full relative overflow-hidden" ani-click-video-wrapper="" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/zhushe_720.flv" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/zhushe_368.flv" flow-target="">
                                                                    <div class="c-video h-full w-full z-1" ani-video-wrapper="">
                                                                        <canvas width="720" height="1584" style="object-fit: cover;"></canvas>
                                                                    </div><img class="poster h-full w-full object-cover absolute top-0 z-2 loaded" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-1-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-1-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-1_mo-1.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-1_mo-1.jpg.webp" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-1-1.jpg.webp" data-ll-status="loaded">
                                                                </div>
                                                            </div>
                                                            <div class="mask w-full h-297rpx h-221ex top-180ex top-[144apx] h-[258apx] top-304rpx left-0 absolute z-2"></div>
                                                            <div class="desc-content absolute top-0 left-0 h-full z-3 w-full p-24px pad:p-14px mo:p-14px flex justify-between items-end">
                                                                <div class="left left-1">
                                                                    <div class="font-24 font-b font-20m font-20s text-top text-white text-opacity-95">
                                                                        <div>超光影三主摄</div>
                                                                        <div>塑光绘影，如臻实境</div>
                                                                    </div>
                                                                    <div class="font-b font-16 font-14m font-14s text-bottom text-white text-opacity-75 mt-12rpx mt-8px mt-6ex">
                                                                        <div>1 英寸大底广角</div>
                                                                        <div>超光感潜望长焦</div>
                                                                        <div>自由曲面超广角</div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-5rpx"><img class="w-16px h-16px loaded" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-ll-status="loaded"></div>
                                                            </div>
                                                        </a><a class="light-box h-480rpx w-328ex h-260ex pad:h-[240apx] relative mo:mb-8px pad:mb-4px w-[358apx] cursor-pointer block overflow-hidden" data-gtm-click="Highlights" data-gtm-label="超光感潜望长焦" href="#section-inch">
                                                            <div class="w-full h-full relative z-1">
                                                                <div class="w-full h-full relative overflow-hidden" ani-click-video-wrapper="" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/changjiao_720.flv" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/changjiao_368.flv" flow-target="">
                                                                    <div class="c-video h-full w-full z-1" ani-video-wrapper="">
                                                                        <canvas width="720" height="1584" style="object-fit: cover;"></canvas>
                                                                    </div><img class="poster h-full w-full object-cover absolute top-0 z-2 loaded" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-2-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-2-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-2_mo-1.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-2_mo-1.jpg.webp" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-2-1.jpg.webp" data-ll-status="loaded">
                                                                </div>
                                                            </div>
                                                            <div class="mask w-full h-297rpx h-221ex top-180ex top-[144apx] h-[258apx] top-304rpx left-0 absolute z-2"></div>
                                                            <div class="desc-content absolute top-0 left-0 h-full z-3 w-full p-24px pad:p-14px mo:p-14px flex justify-between items-end">
                                                                <div class="left">
                                                                    <div class="font-24 font-b font-20m font-20s text-top text-white text-opacity-95">
                                                                        <div>超光感潜望长焦</div>
                                                                        <div>让长焦跨越明暗</div>
                                                                    </div>
                                                                    <div class="font-b font-16 font-14m font-14s text-bottom text-white text-opacity-75 mt-12rpx mt-8px mt-6ex">
                                                                        <div>悬浮棱镜防抖</div>
                                                                        <div>6 倍光学品质变焦¹</div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-5rpx"><img class="w-16px h-16px loaded" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-ll-status="loaded"></div>
                                                            </div>
                                                        </a><a class="light-box h-480rpx w-328ex h-260ex pad:h-[240apx] relative mo:mb-8px pad:mb-4px w-[358apx] cursor-pointer block overflow-hidden" data-gtm-click="Highlights" data-gtm-label="哈苏移动影像系统" href="#section-hasee">
                                                            <div class="w-full h-full relative z-1">
                                                                <div class="w-full h-full relative overflow-hidden" ani-click-video-wrapper="" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/hasu_720.flv" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/hasu_368.flv" flow-target="">
                                                                    <div class="c-video h-full w-full z-1" ani-video-wrapper="">
                                                                        <canvas width="720" height="1584" style="object-fit: cover;"></canvas>
                                                                    </div><img class="poster h-full w-full object-cover absolute top-0 z-2 loaded" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-3-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-3-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-3_mo-1.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-3_mo-1.jpg.webp" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-3-1.jpg.webp" data-ll-status="loaded">
                                                                </div>
                                                            </div>
                                                            <div class="mask w-full h-297rpx h-221ex top-180ex top-[144apx] h-[258apx] top-304rpx left-0 absolute z-2"></div>
                                                            <div class="desc-content absolute top-0 left-0 h-full z-3 w-full p-24px pad:p-14px mo:p-14px flex justify-between items-end">
                                                                <div class="left">
                                                                    <div class="font-24 font-b font-20m font-20s text-top text-white text-opacity-95">
                                                                        <div>哈苏移动影像系统</div>
                                                                        <div>用科技诠释经典</div>
                                                                    </div>
                                                                    <div class="font-b font-16 font-14m font-14s text-bottom text-white text-opacity-75 mt-12rpx mt-8px mt-6ex">
                                                                        <div>哈苏人像模式</div>
                                                                        <div>哈苏专业模式</div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-5rpx"><img class="w-16px h-16px loaded" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-ll-status="loaded"></div>
                                                            </div>
                                                        </a><a class="light-box h-480rpx w-328ex h-260ex pad:h-[240apx] relative mo:mb-8px pad:mb-4px w-[358apx] cursor-pointer block overflow-hidden" data-gtm-click="Highlights" data-gtm-label="动态超光影屏" href="#section-light">
                                                            <div class="w-full h-full relative z-1">
                                                                <div class="w-full h-full relative overflow-hidden" ani-click-video-wrapper="" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/guangyin_720.flv" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/guangyin_368.flv" flow-target="">
                                                                    <div class="c-video h-full w-full z-1" ani-video-wrapper="">
                                                                        <canvas width="720" height="1584" style="object-fit: cover;"></canvas>
                                                                    </div><img class="poster h-full w-full object-cover absolute top-0 z-2 loaded" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-4-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-4-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-4_mo-1.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-4_mo-1.jpg.webp" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp_poster-4-1.jpg.webp" data-ll-status="loaded">
                                                                </div>
                                                            </div>
                                                            <div class="mask w-full h-297rpx h-221ex top-180ex top-[144apx] h-[258apx] top-304rpx left-0 absolute z-2"></div>
                                                            <div class="desc-content absolute top-0 left-0 h-full z-3 w-full p-24px pad:p-14px mo:p-14px flex justify-between items-end">
                                                                <div class="left">
                                                                    <div class="font-24 font-b font-20m font-20s text-top text-white text-opacity-95">
                                                                        <div>动态超光影屏</div>
                                                                        <div>巅峰亮度，如临其境</div>
                                                                    </div>
                                                                    <div class="font-b font-16 font-14m font-14s text-bottom text-white text-opacity-75 mt-12rpx mt-8px mt-6ex">
                                                                        <div>峰值亮度 2500 尼特²</div>
                                                                        <div>ProXDR 显示</div>
                                                                    </div>
                                                                </div>
                                                                <div class="p-5rpx"><img class="w-16px h-16px loaded" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-arrow-1.svg" data-ll-status="loaded"></div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div class="video-container w-full h-[var(--content-height)] max-h-640ex relative overflow-hidden" id="anchor-design" data-gtm-view="Part3: 圆融有序大器之美" img-flow="" data-offset-mo="10%">
                                                        <div class="absolute h-full w-full top-0 left-0 flex z-2 justify-center items-center">
                                                            <div class="font-68 font-48m font-b fade-in" ani-fade-in="">
                                                                <div>圆融有序</div>
                                                                <div>大器之美</div>
                                                            </div>
                                                        </div>
                                                        <div class="video w-full h-full" ani-autoplay-video="" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/kv_pc_1440.flv" data-src-pad="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/kv_pc_1440.flv" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/kv_mo_368.flv" flow-target="" style="transform: translateY(-15%) scale(1.2);">
                                                            <canvas width="1440" height="800" style="object-fit: cover;"></canvas>
                                                        </div>
                                                        <div class="ksp-video-part absolute bottom-[-2px] z-2"></div>
                                                    </div>
                                                    <div class="pro-movie relative w-50vw m-auto pt-94rpx w-328ex pt-80px pad:w-[720apx]" data-gtm-view="Part4: 专业影像表里如一" leave-container="" style="transform: matrix(1, 0, 0, 1, 0, 0);">
                                                        <div class="text-container text-center mo:text-left w-max m-auto w-328ex relative z-1" pro-movie-text="" style="transform: translateY(200%);">
                                                            <div class="font-48 font-30m font-b">专业影像，表里如一</div>
                                                            <div class="font-18 font-16m mt-24rpx pc:w-462px m-auto mt-18px pad:w-431px">致敬经典相机设计，以圆融美学承载专业影像，内在实力，一眼可辨。</div>
                                                        </div><img class="w-[160vh] h-[54.52vh] ml-[calc(25vw-80vh)] pad:w-[768apx] pad:h-[262apx] pad:ml-[-24apx] relative z-2 mo-hidden object-cover loaded" pro-movie-img="" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-phone-1-95.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-phone-d7111-95.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-phone-1-95.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-phone-1-95.png.webp" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-phone-1-95.png.webp" data-ll-status="loaded" style="transform: scale(1.3);">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elegant-black w-full relative z-2" ani-leave="" style="transform-origin: 50% 100%; transform: scale(1); border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;">
                                                <div class="slick-part w-full pt-139rpx pb-64rpx pad:pt-[80px]">
                                                    <div class="desc-box w-1312rpx w-[720apx] w-328ex mo:flex-col flex m-auto" data-gtm-view="Part5: 视觉与触觉的通感">
                                                        <div class="desc pc:w-[50%] h-645rpx h-[428apx] bg-black mo:bg-[#1f1f1f] pl-64rpx pl-[20px] mo:pl-[0px] flex items-center">
                                                            <div class="mo:pt-80px">
                                                                <div class="title font-48 font-30m font-b">
                                                                    <div>精致，精密，精巧</div>
                                                                    <div>视觉与触觉的通感</div>
                                                                </div>
                                                                <div class="font-18 text-white mt-24rpx w-480rpx mt-18px font-16m">以精致设计和精密工艺诠释精巧布局。细腻光影，从眼里流转到手中，融合无间。</div>
                                                            </div>
                                                        </div>
                                                        <div class="img-box bg-[#EFEDE9] w-[50%] h-645rpx w-360ex ml-[-16ex] h-400ex mo:mt-24px" img-flow="" data-scale="1" data-offset="-15%"><img class="top-[9%] pad:top-[7%] w-full h-470ex h-800rpx object-cover loaded" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-desc-1-90.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-desc-d7111-90.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-desc-mo-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-desc-pad-1.png.webp" style="transform: translateY(-15%) scale(1);" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-desc-1-90.png.webp" data-ll-status="loaded"></div>
                                                    </div>
                                                    <div class="slick-container flex w-1312rpx w-[720apx] m-auto w-360ex mo:mt-80px mo:flex-col" data-gtm-view="Part6: 颜色" slick-active="1">
                                                        <div class="img-box w-[50%] h-645rpx h-[428apx] w-360ex relative h-260ex" img-flow="" data-scale="1.1" data-offset="-15%">
                                                            <div class="w-full h-full absolute top-0 left-0" img-slick="3"><img class="w-full h-full object-cover absolute top-[5%]" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-3-1-99.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-3-d7111-99.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-3-mo.jpg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-3-1-99.jpg.webp" style="transform: translateY(-15%) scale(1.1);"></div>
                                                            <div class="w-full h-full absolute top-0 left-0" img-slick="2"><img class="w-full h-full object-cover absolute top-[5%]" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-2-1-99.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-2-d7111-99.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-2-mo.jpg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-2-1-99.jpg.webp" style="transform: translateY(-15%) scale(1.1);"></div>
                                                            <div class="w-full h-full absolute top-0 left-0" img-slick="1"><img class="w-full h-full object-cover absolute top-[5%]" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-1-1-99.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-1-d7111-99.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-1-mo.jpg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-main-pro-1-1-99.jpg.webp" style="transform: translateY(-15%) scale(1.1);"></div>
                                                        </div>
                                                        <div class="right w-[50%] h-645rpx h-[428apx] p-[24apx] pt-[32apx] mo:pt-24px w-360ex mo:pb-32px mo:bg-black m-auto p-64rpx bg-black relative flex flex-col">
                                                            <div class="img-box w-532rpx w-328ex h-150ex w-[312apx] mo:m-auto h-[157apx] h-270rpx relative overflow-hidden elegant-black"><img class="w-full h-full object-cover absolute top-0 left-0" img-pro="3" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-3.jpg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-3.jpg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-3-mo.jpg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-3.jpg"><img class="w-full h-full object-cover absolute top-0 left-0" img-pro="2" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-2.jpg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-2.jpg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-2-mo.jpg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-2.jpg"><img class="w-full h-full object-cover absolute top-0 left-0" img-pro="1" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-1.jpg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-1.jpg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-1-mo.jpg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-pro-1.jpg">
                                                            </div>
                                                            <div class="text-box relative mt-24rpx mt-12px w-full w-328ex m-auto text-white text-opacity-95 font-12m font-16 font-14p">
                                                                <div class="relative" pro-index="1">镜组的流动光影，碰撞素皮的细腻内敛，带你踏入月华笼罩的无垠沙漠。是感官之美，更蕴含一种态度：不论身处何种复杂环境，也要微笑前行的态度。只要坚持“不分昼夜，无论远近”的精神，就一定能守得云开见月明。</div>
                                                                <div class="absolute top-0 left-0" pro-index="2">清幽的色彩，细腻的质地，仿佛于湍急的林间清泉中取一斛静谧，濯尽赶路的浮尘。</div>
                                                                <div class="absolute top-0 left-0" pro-index="3">如同富饶的矿藏被开掘的一瞬，黑得深邃，又散发着丝绸般的光泽。</div>
                                                            </div>
                                                            <div class="control flex pc:absolute bottom-64rpx bottom-[32apx] bo-12px pl-16ex font-14m mo:mt-32px font-b left-64rpx">
                                                                <div data-gtm-click="颜色" style="--circle-color:#ED973E" btn-index="1">
                                                                    <div class="btn-circle"></div>
                                                                    <div class="font-16">大漠银月</div>
                                                                </div>
                                                                <div class="mx-20px" data-gtm-click="颜色" style="--circle-color:#99BCB7" btn-index="2">
                                                                    <div class="btn-circle"></div>
                                                                    <div class="font-16">飞泉绿</div>
                                                                </div>
                                                                <div data-gtm-click="颜色" style="--circle-color:#484540" btn-index="3">
                                                                    <div class="btn-circle"></div>
                                                                    <div class="font-16">云墨黑</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="ksp-footer w-1312rpx m-auto text-center pb-128rpx pt-80px pb-64px pad:pb-48px pt-80rpx" data-gtm-view="Part7: IP68级防尘抗水">
                                                    <div class="font-48 font-b font-30m">穿山涉水中笃定的安全感</div>
                                                    <div class="font-18 mt-24rpx mt-18px font-16m">IP68 级防尘抗水³，安心享受每一段旅程。</div>
                                                    <div class="img-wrapper w-1312rpx w-[720apx] h-[360apx] h-558rpx mt-40rpx mt-24px m-auto h-400ex w-328ex" img-flow="" data-scale="1" data-scale-mo="1" data-offset="0">
                                                        <div class="relative w-full h-full" flow-target="" style="transform: translateY(0px) scale(1);"><img class="w-1986rpx w-[1260apx] h-[748apx] left-[-270apx] left-[-337rpx] h-1174rpx top-[-194apx] top-[-308rpx] mo:top-[-73ex] mo:left-[-169ex] h-546ex w-666ex object-cover" flow-target-scale="" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-bg-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-bg-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-bg-mo-1.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-bg-pad-1.jpg.webp" style="transform: scale(0.7);"><img class="w-full h-full h-400ex w-328ex object-cover" alt="alt" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-phone-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-phone-d7111.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-phone-mo.png" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-ksp-footer-phone-pad-1.png.webp"></div>
                                                    </div>
                                                </div>
                                                <div class="dark-mask" style="border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; opacity: 0;"></div>
                                            </div>
                                        </div>
                                        <div class="bg-black mo-hidden relative z-1 -mt-1px" id="section-camera" ani-camera="">
                                            <div class="camera-sticky-wrapper h-200vh">
                                                <div class="sticky pc:h-100vh pad:h-[calc(var(--content-height))] top-[calc(var(--nav-height))] overflow-hidden flex justify-center">
                                                    <div class="w-100vw h-full relative flex justify-center items-end ">
                                                        <div class="camera-title text-white text-opacity-95 font-68 font-48m font-b absolute z-2 pc:bottom-[calc(58vh+20px)] pad:bottom-[unset] pad:top-0" style="transform: translateY(94.7px) translateX(20px);">全主摄时代<br>现在开启</div>
                                                        <div class="camera-text1 flex justify-between items-start absolute z-5 bottom-36.3vh pc:max-w-96vw pad:w-[720apx]" style="transform: translateY(108.4px) translateX(13.55px); opacity: 0;">
                                                            <div class="text-white text-opacity-95 font-48 font-42px-1024 font-b font-30m min-w-[224apx] pad:flex pad:justify-end whitespace-nowrap">超光影三主摄<br>颗颗专业</div>
                                                            <div class="text-white text-opacity-95 font-18 text1-desc font-16m w-[224apx]">领先的传感器配置，卓越的移动光学技术，让每颗镜头的进光量都有了史无前例的飞跃。远或近，明或暗，开阔或聚焦，顺光或逆光，用专业给你随心所欲的创作自由。</div>
                                                        </div>
                                                        <div class="camera-line-text pc:w-127.28vh pad:w-[720apx] flex justify-between absolute z-5 flex-wrap pad:top-0 z-6" style="bottom: 83.9px; transform: translateY(54.2px) translateX(8.13px); opacity: 0;">
                                                            <div class="text-wrapper text-white text-opacity-95 relative ">
                                                                <div class="title font-24 font-b font-20m">1 英寸大底广角</div>
                                                                <div class="line my-12rpx mt-[8apx] mb-[10apx] relative line0 !w-[98%]">
                                                                    <div class="relative overflow-hidden w-full"><img class="line-w line-w0 !pad:h-auto pad:w-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-0-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-0-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-0-1.svg"></div>
                                                                </div>
                                                                <div class="desc font-18 font-b opacity-75 font-14m  text-left">F1.8 超大光圈<br>OIS 光学防抖<br>高透光玻璃镜片<br>ALC 亚波长结构镀膜</div>
                                                            </div>
                                                            <div class="text-wrapper text-white text-opacity-95 relative ">
                                                                <div class="title font-24 font-b font-20m">自由曲面超广角</div>
                                                                <div class="line my-12rpx mt-[8apx] mb-[10apx] relative line1 !w-[285apx]">
                                                                    <div class="relative overflow-hidden w-full"><img class="line-w line-w1 !pad:h-auto pad:w-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-1-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-1-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-1-1.svg"></div>
                                                                </div>
                                                                <div class="desc font-18 font-b opacity-75 font-14m  text-right">1/1.56 英寸传感器面积<br>F2.2 大光圈<br>110° 广视角<br>自由曲面镜片</div>
                                                            </div>
                                                            <div class="text-wrapper text-white text-opacity-95 relative mt-[calc(27.28vh-130px-32px-24rpx)] mt-[12apx]">
                                                                <div class="title font-24 font-b font-20m">超光感潜望长焦</div>
                                                                <div class="line my-12rpx mt-[8apx] mb-[10apx] relative line2 !w-[98%]">
                                                                    <div class="absolute pc:right-[3.5px] pad:right-0 bottom-0 pc:!w-0.25vh !h-[53apx] !pad:w-auto !h-6.54vh overflow-hidden" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg"><img class="line-h !w-full !h-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-h-1.svg"></div>
                                                                    <div class="relative overflow-hidden w-full"><img class="line-w line-w2 !pad:h-auto pad:w-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-2-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-2-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-line-2-1.svg"></div>
                                                                </div>
                                                                <div class="desc font-18 font-b opacity-75 font-14m  text-left">1/1.56 英寸传感器面积<br>F2.6 大光圈<br>悬浮棱镜防抖<sup>4</sup><br>65mm 黄金焦段</div>
                                                            </div>
                                                        </div>
                                                        <div class="mask w-full h-21vh absolute bottom-0 z-4"></div>
                                                        <div class="camera-phone-wrapper pc:w-49.7vh pc:h-134.7vh w-[212apx] h-[606apx] relative transform origin-top z-3" style="transform: scale(1.4) translateY(281.84px);"><img class="phone w-full h-full absolute top-0 left-0 z-1" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-phone-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-phone-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-phone-pad-1.png.webp"><img class="camera w-full h-full absolute z-2" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-camera-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-camera-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-camera-camera-1.png.webp" style="opacity: 1;"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="bg-black text-white text-opacity-95 -mt-1px" id="section-inch" ani-inch="">
                                            <div class="w-1312rpx w-[720apx] mx-auto w-328ex" data-gtm-view="Part11: 1英寸大底广角">
                                                <div class="w-full mx-auto flex pc:justify-center pt-120rpx mb-70rpx mb-24ex mb-[40apx] pt-78ex pt-[80apx]">
                                                    <div class="font-48 font-30m font-b">1 英寸大底广角<br>捕捉光，“一丝”不苟</div>
                                                </div>
                                                <div class="content-wrapper1 w-1312rpx w-[720apx] mx-auto h-1047rpx flex mo:flex-col pl-175rpx pl-[91apx]" style="transform: translateX(-612px);">
                                                    <div class="wrapper-left w-350rpx w-[247apx] mr-87rpx mr-[47apx] flex pc:flex-col mo:flex-col-reverse justify-end">
                                                        <div class="text font-18 font-16m w-335rpx my-32ex mt-[100apx] w-[247apx]" style="opacity: 0;">光的采集，是影像的起点。广角镜头采用 1 英寸超大面积传感器，配合 F1.8 超大光圈镜组，带来前所未有的感光能力提升。低光环境下，仍能拍出清晰、细腻、生动的高光时刻。</div>
                                                        <div class="inch-img-wrapper w-[247apx] h-[302apx] w-350rpx h-426rpx w-328ex h-180ex relative overflow-hidden mt-[22apx]" img-flow="" data-scale="1" style="transform: translateX(612px) translateY(40px);"><img class="w-full h-[120%] absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-2-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-2-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-1-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-2-pad-1.jpg.webp" style="transform: translateY(-15%) scale(1);"></div>
                                                    </div>
                                                    <div class="wrapper-right h-619rpx h-[322apx] relative" style="transform: translateY(0px);">
                                                        <div class="inch-img-wrapper mo-hidden w-[245apx] h-[322apx] w-457rpx h-619rpx w-206ex h-260ex relative overflow-hidden ml-122ex" style="width: 1312px; height: 500px; transform: translateZ(0px);"><img class="w-full pad:h-[120%] h-712rpx mo:h-[120%] absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-1-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-1-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-2-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-1-pad-1.jpg.webp" style="transform: translateY(-8%) scale(1);"></div>

                                                    </div>
                                                </div>
                                                <div class="w-[539apx] h-[272apx] w-931rpx h-458rpx relative overflow-hidden mt-81rpx ml-[91apx] mt-[56apx] ml-175rpx w-272ex h-170ex mt-40ex" img-flow="" data-scale="1"><img class="w-full h-[120%] absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-3-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-3-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-3-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-3-pad-1.jpg.webp" style="transform: translateY(-15%) scale(1);"></div>
                                                <div class="content-wrapper2 pt-162rpx pt-[121apx] flex mo:flex-col ml-175rpx ml-[91apx] pt-80ex" data-gtm-view="Part12: 超光感潜望长焦">
                                                    <div class="w-458rpx w-[267apx]">
                                                        <div class="font-48 font-b mb-142rpx mb-[90apx] font-30m font-42px-1024">超光感潜望长焦<br>远见，无分昼夜</div>

                                                        <div class="img4 w-458rpx h-599rpx w-328ex h-181ex w-[267apx] h-[329apx] mb-4ex mt-24ex relative overflow-hidden" img-flow="" data-scale="1" style="transform: translateY(0px);"><img class="w-full h-[120%] absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-4-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-4-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-4-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-4-pad-1.jpg.webp" style="transform: translateY(-15%) scale(1);"></div>
                                                    </div>
                                                    <div class="w-416rpx ml-64rpx mb-32ex w-[247apx] ml-[32apx]">
                                                        <div class="w-416rpx w-[245apx] h-[160apx] h-285rpx w-328ex h-260ex relative overflow-hidden mt-120rpx mt-[66apx]" img-flow="" data-scale="1"><img class="w-full h-[120%] absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-5-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-5-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-5-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-5-pad-1.jpg.webp" style="transform: translateY(-15%) scale(1);"></div>
                                                        <div class="mo-hidden font-18 font-14m mt-80rpx w-377rpx mt-[37apx] w-[247apx]">超大底潜望长焦带来行业革命级的感光能力提升，暗光远摄，仍有高画质表现：清晰，细腻，色彩生动，光影真实。搭载悬浮棱镜防抖，带来前所未有的暗光长焦品质。</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-black text-white text-opacity-95 -mt-1px" id="section-zoom">
                                            <div class="pt-20rpx w-1312rpx w-[720apx] mx-auto w-328ex pt-48ex pt-[20apx]" data-gtm-view="Part13: 自有曲面超广角">
                                                <div class="pc:text-center flex flex-col pc:items-center">
                                                    <div class="font-48 font-b font-30m">自由曲面超广角<br>广博，包罗万象</div>
                                                    <div class="font-18 mt-24rpx w-650rpx font-16m mt-18ex mb-24ex w-[431apx] mt-[18apx] pc:min-w-482px">业界领先的传感器尺寸，配合自由曲面镜片，再大的场面，也能清晰再现。</div>
                                                </div>
                                                <div class="w-full h-[240apx] h-398rpx relative overflow-hidden mt-40rpx w-328ex h-426ex mt-[24apx]" img-flow="" data-scale="1" data-offset="-16%" data-offset-mo="-15%"> <img class="w-full mo:h-[120%] pc:h-[120%] absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-6-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-6-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-6-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-inch-img-6-pad-1.jpg.webp" style="transform: translateY(-16%) scale(1);"></div>
                                            </div>
                                            <div class="w-full h-200vh mx-auto flex justify-between zoom-part1-mt mt-[calc(31px+341ex-50vh)] mt-[calc(398.5rpx-50vh)]" ani-zoom="" data-gtm-view="Part14: 超光影三主摄展示">
                                                <div class="sticky h-[calc(var(--content-height))] w-1312rpx w-[720apx] w-328ex mx-auto flex mo:flex-col pc:justify-between mo:justify-center pc:items-center top-[calc(var(--nav-height))]">
                                                    <div class="flex flex-col">
                                                        <div class="font-48 font-30m font-b font-42px-1024">超光影三主摄</div>
                                                        <div class="mt-18ex font-18 font-16m mt-24rpx mb-24ex mt-[18apx]">生动，从近到远</div>
                                                    </div>
                                                    <div class="w-899rpx h-461rpx w-[455apx] h-[233apx] w-328ex h-380ex relative overflow-hidden">
                                                        <div class="size-img w-full h-full absolute top-0 left-0 z-3 overflow-hidden" style="clip-path: inset(0px 0px 0%);"><img class="w-full h-[120%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom0-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom0-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom0-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom0-pad-1.jpg.webp" style="transform: translateY(-16%);"></div>
                                                        <div class="size-img w-full h-full absolute top-0 left-0 z-2 overflow-hidden" style="clip-path: inset(0px 0px 0%);"><img class="w-full h-[120%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom1-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom1-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom1-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom1-pad-1.jpg.webp" style="transform: translateY(-16%);"></div>
                                                        <div class="size-img w-full h-full absolute top-0 left-0 z-1 overflow-hidden"><img class="w-full h-[120%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom2-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom2-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom2-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-zoom2-pad-1.jpg.webp" style="transform: translateY(-16%);"></div>
                                                        <div class="size-wrapper w-full absolute top-0 left-0 flex flex-col z-5" style="transform: translateY(0%);">
                                                            <div class="cover w-899rpx h-461rpx w-328ex h-380ex w-[455apx] h-[233apx] relative">
                                                                <div class="black-mask w-full h-full absolute top-0 left-0 bg-black opacity-0" style="opacity: 0;"></div>
                                                                <div class="font-32 absolute mo:top-10px mo:left-12ex top-18rpx left-28rpx left-[15apx] top-[10apx] font-b opacity-95 mo:text-[18px] mo:tracking-[0.5px] mo:leading-[26px] pad:text-[16px]">0.6X</div>
                                                            </div>
                                                            <div class="w-full h-2rpx h-[1apx] bg-white h-2ex"></div>
                                                            <div class="cover w-899rpx h-461rpx w-328ex h-380ex w-[455apx] h-[233apx] relative">
                                                                <div class="black-mask w-full h-full absolute top-0 left-0 bg-black opacity-0" style="opacity: 0;"></div>
                                                                <div class="font-32 absolute mo:top-10px mo:left-12ex top-18rpx left-28rpx left-[15apx] top-[10apx] font-b opacity-95 mo:text-[18px] mo:tracking-[0.5px] mo:leading-[26px] pad:text-[16px]">1X</div>
                                                            </div>
                                                            <div class="w-full h-2rpx h-[1apx] bg-white h-2ex"></div>
                                                            <div class="cover w-899rpx h-461rpx w-328ex h-380ex w-[455apx] h-[233apx] relative">
                                                                <div class="black-mask w-full h-full absolute top-0 left-0 bg-black opacity-0"></div>
                                                                <div class="font-32 absolute mo:top-10px mo:left-12ex top-18rpx left-28rpx left-[15apx] top-[10apx] font-b opacity-95 mo:text-[18px] mo:tracking-[0.5px] mo:leading-[26px] pad:text-[16px]">3X</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w-1312rpx w-[720apx] mx-auto zoom-part2-mt pb-86rpx w-328ex py-48ex" data-gtm-view="Part15: 超光影图像引擎">
                                                <div class="flex mo:flex-col justify-between">
                                                    <div class="font-48 font-30m font-b font-42px-1024">超光影图像引擎<br>驾驭光<span class="pad-hidden">，</span>让画面生动可感</div>
                                                    <div class="font-18 font-16m mt-18ex w-632rpx w-[445apx]">Find X 系列有史以来最强大的图像引擎，智能调用马里亚纳® MariSilicon X 影像专用 NPU 芯片强大算力，重构影像处理流程，有效提升画面纯净度；独创光子矩阵地图，像素级标定亮度信息，还原真实光影。赋予画面可步入般的临场感。</div>
                                                </div>
                                                <div class="flex mo:flex-col justify-between h-530rpx pc:w-full h-[290apx] w-328ex mt-28rpx mt-[24apx] mt-24ex">
                                                    <div class="w-[510apx] w-934rpx pc:h-full h-210ex relative" img-flow="" data-scale="1.25" data-offset="-12%"><img class="w-full h-full absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img1-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img1-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img1-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img1-pad-1.jpg.webp" style="transform: translateY(-12%) scale(1.25);"></div>
                                                    <div class="w-[206apx] w-370rpx pc:h-full h-140ex relative mt-4ex" img-flow="" data-scale="1.25" data-offset="-12%"><img class="w-full h-full absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img2-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img2-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img2-mo-1-95.jpg.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-zoom-img2-pad-1.jpg.webp" style="transform: translateY(-12%) scale(1.25);"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-black -mt-1px pad-hidden" id="section-hasee">
                                            <div class="w-1312rpx mx-auto pt-70rpx pt-80ex flex mo:flex-col pc:justify-between pc:items-end w-328ex">
                                                <div class="text-white text-opacity-95 font-48 font-b font-36m font-42px-1024">哈苏移动影像<br>科学影像携手美学传奇</div><img class="w-684rpx w-328ex pc:pb-12px mt-24ex mb-64ex" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-title-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-title-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-title-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-title-1.svg">
                                            </div>
                                            <div ani-hasee="" style="margin-top: -67.5px; height: 4601.5px;">
                                                <div class="pc:sticky pc:h-[calc(var(--content-height))] top-[var(--nav-height)] w-100vw w-328ex overflow-hidden flex mo:flex-col items-center mo:mx-auto">
                                                    <div class="content-wrapper flex mo:flex-col ml-[calc(50vw-656rpx)] pc:h-76.85vh max-h-680rpx text-white text-opacity-95 h-full items-center" style="width: 4601.5px; transform: translateX(0px) translateZ(0px);">
                                                        <div class="img-wrapper pc:h-[100%] w-328ex h-326ex mb-20ex overflow-hidden relative" style="width: 1003.57px;"><img class="w-full h-full absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-0-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-0-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-0-mo-1.jpg.webp"></div>
                                                        <div class="text-wrapper pl-42rpx pr-120rpx" style="transform: translateX(0px) translateZ(0px);">
                                                            <div class="font-36 font-24m font-b">哈苏自然色彩优化<br>专业的，更美</div>
                                                            <div class="font-18 font-16m mt-12ex mt-24rpx pc:w-397px">做色彩，强强联手。OPPO 色彩科学带来精准的色彩捕捉，更有哈苏自然色彩解决方案还原细腻、鲜活的斑斓万象。专业拍档，拍出色。</div>
                                                        </div>
                                                        <div class="img-wrapper pc-only pc:h-[77.13%] overflow-hidden relative mt-10vh" style="width: 391.422px;"><img class="w-full h-full absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-1-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-1-d7111.jpg.webp"></div>
                                                        <div class="img-wrapper pc-only pc:h-[83.08%] overflow-hidden relative ml-20rpx -mt-1.3vh" style="width: 455.034px;"><img class="w-full h-full absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-2-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-2-d7111.jpg.webp"></div>

                                                        <div class="text-wrapper pl-42rpx pr-150rpx" style="transform: translateX(50px) translateZ(0px);">
                                                            <div class="font-36 font-24m font-b">哈苏人像<br>每一拍，都是作品</div>
                                                            <div class="font-18 font-16m mt-12ex mt-24rpx pc:w-329px">卓越的影像配置，哈苏专业调校，呈现更自然的类光学虚化效果。配合哈苏经典人像视角，让作品级的人像效果触手可及。你站在哪里，哪里就是最佳影棚。</div>
                                                        </div>
                                                        <div class="img-wrapper pc:h-[96.28%] w-328ex h-326ex overflow-hidden relative pc:mt-[-1vh] mb-20ex mt-80ex" style="width: 935.714px;"><img class="w-full h-full absolute top-0 left-0 object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-3-1.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-3-d7111.jpg.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-hasee-img-3-mo-1.jpg.webp"></div>
                                                        <div class="text-wrapper pl-42rpx pr-164rpx mo:w-full" style="transform: translateX(40px) translateZ(0px);">
                                                            <div class="font-36 font-24m font-b pc:w-333px">哈苏专业模式<br>凝结光影，诠释经典</div>
                                                            <div class="font-18 font-16m mt-12ex mt-24rpx pc:w-304px">专业模式承继哈苏相机色彩调校，带来更自然和专业的影像质感。人像、风光、人文，拍什么都是哈苏质感。得益于 Find X6 Pro 强大的超光影图像引擎，在呈现丰富细节的同时，留住立体影调。</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="w-screen relative z-1 -mt-1rpx bg-[#000000] text-[#fff]" id="section-video-recoding" an-recoding="">
                                            <div class="mo-hidden">
                                                <div class="stick-wrapper relative" data-gtm-view="Part19: 一入镜就是电影质感" style="height: 1008.5px;">
                                                    <div class="sticky top-[var(--nav-height)] !box-border" style="height: 825px;">
                                                        <div class="relative w-full h-full">
                                                            <div class="relative w-full h-[var(--content-height)] overflow-hidden" an-recoding-sticky="">
                                                                <div class="wrapper absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-411rpx h-[240apx]">
                                                                    <div class="relative w-full h-full">
                                                                        <div class="content absolute whitespace-nowrap transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                                                                            <div class="differential w-full h-full" style="transform: translateY(-162.6px) translateZ(0px);">
                                                                                <div class="tranCon font-68 font-b !pad:text-30px !pad:leading-[127%]" style="opacity: 1; transform: translateY(0px) translateZ(0px);">一入镜</div>
                                                                                <div class="tranCon font-68 font-b !pad:text-30px !pad:leading-[127%]" style="opacity: 1; transform: translateY(0px) translateZ(0px);">就是电影质感</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="clip-video w-883rpx w-[560apx] h-full" style="clip-path: inset(50% 0%);">
                                                                            <div class="video-wrapper relative w-full h-full">
                                                                                <div class="video relative w-full h-full" ani-video-wrapper="" ani-one-video="" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/hdr_480.flv" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/hdr.mp4">
                                                                                    <div class="video-wrapper w-full h-full">

                                                                                        <video autoplay="" muted="" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/hdr.mp4"></video>
                                                                                    </div>
                                                                                    <div class="replay cursor-pointer opacity-1 absolute flex !items-center bottom-20rpx bottom-[8apx] right-[8apx] rounded-[40apx] right-20rpx rounded-40rpx bg-[#000] bg-opacity-22 py-4rpx px-12rpx py-[4apx] px-[12apx] right-8ex bottom-8ex rounded-40ex px-12ex py-4ex" data-gtm-click="杜比视界HDR规格视频录制" data-gtm-label="重播">
                                                                                        <div class="!relative !pc:w-20px !mo:w-16px !mo:h-16px !pad:w-16px !pc:h-20px !pad:h-16px"><img class="w-full h-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg"></div>
                                                                                        <div class="font-14 w-28px h-22px pad:w-24px pad:h-20px mo:w-24px mo:h-20px ml-2px !pad:text-12px !pad:leading-[167%] !tracking-[0.5px] !mo:text-12px !mo:leading-[167%]">重播</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="text-wrapper w-742rpx absolute transform left-1/2 -translate-x-1/2  top-[calc(50vh+227rpx)] pad:top-[calc(50vh+104apx)] flex flex-col items-center">
                                                                <div class="text w-full text-center" style="opacity: 0; transform: translateY(10px) translateZ(0px);">
                                                                    <div class="font-48 whitespace-nowrap font-b font-30m">杜比视界 HDR 视频录制<br>栩栩如生，浑然天成</div>
                                                                    <div class="font-18 w-742rpx mt-24rpx mt-[18apx] w-[597apx] font-16m">想拍个 vlog？现在，出手就是电影级。Find X 系列首次支持杜比视界 HDR 规格的视频录制，准备好记录你更高“动态”的“动态“了吗？同时支持双耳录音 2.0<sup>5</sup>，声、画，双双如临其境。</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="video-play w-1312rpx w-[720apx] pc:mx-auto pt-80rpx pb-[64apx] pb-88rpx px-16ex pb-48ex " data-gtm-view="Part21: 全焦段4K高清视频">
                                                <div class="mo-hidden text-opacity-95 mb-40rpx mb-24ex mb-[24apx]">
                                                    <div class="title font-48 font-b font-30m">全焦段 4K 高清视频</div>
                                                    <div class="desc font-18 mt-24rpx mt-18ex mt-[18apx] font-16m w-1200rpx w-[445apx]">配合超光影三主摄，Find X6 Pro 支持多焦段 4K 60fps 的高品质录制，为你的高清大作带来更丰富的镜头语言。</div>
                                                </div>
                                                <div class="video-wrapper flex mo:flex-col w-1312rpx w-[720apx] mo:w-full overflow-hidden">
                                                    <div class="relative w-652rpx h-372rpx w-328ex h-200ex w-[356apx] h-[220apx]">
                                                        <div class="video relative w-full h-full" ani-video-wrapper="" ani-one-video="" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/4k_1_480.flv" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/4k_1.mp4">
                                                            <div class="video-wrapper w-full h-full">

                                                                <video autoplay="" muted="" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/4k_1.mp4"></video>
                                                            </div>
                                                            <div class="replay cursor-pointer opacity-1 absolute flex !items-center bottom-20rpx bottom-[8apx] right-[8apx] rounded-[40apx] right-20rpx rounded-40rpx bg-[#000] bg-opacity-22 py-4rpx px-12rpx py-[4apx] px-[12apx] right-8ex bottom-8ex rounded-40ex px-12ex py-4ex" data-gtm-click="全焦段4K高清视频" data-gtm-label="1X+重播">
                                                                <div class="!relative !pc:w-20px !mo:w-16px !mo:h-16px !pad:w-16px !pc:h-20px !pad:h-16px"><img class="w-full h-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg"></div>
                                                                <div class="font-14 w-28px h-22px pad:w-24px pad:h-20px mo:w-24px mo:h-20px ml-2px !pad:text-12px !pad:leading-[167%] !tracking-[0.5px] !mo:text-12px !mo:leading-[167%]">重播</div>
                                                            </div>
                                                        </div>
                                                        <div class="text-[#000] z-100 absolute px-28rpx py-10rpx py-8ex px-20ex py-[8apx] px-[19apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] left-26rpx bottom-16rpx left-8ex bottom-12ex left-[16apx] bottom-[16apx] font-18 font-16m">1X</div>
                                                    </div>
                                                    <div class="relative w-652rpx h-372rpx w-328ex h-200ex w-[356apx] h-[220apx] ml-8rpx mt-4ex ml-[4apx]">
                                                        <div class="video relative w-full h-full" ani-video-wrapper="" ani-one-video="" data-src-mo="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/4k_2_480.flv" data-src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/4k_2.mp4">
                                                            <div class="video-wrapper w-full h-full">

                                                                <video autoplay="" muted="" src="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/4k_2.mp4"></video>
                                                            </div>
                                                            <div class="replay cursor-pointer opacity-1 absolute flex !items-center bottom-20rpx bottom-[8apx] right-[8apx] rounded-[40apx] right-20rpx rounded-40rpx bg-[#000] bg-opacity-22 py-4rpx px-12rpx py-[4apx] px-[12apx] right-8ex bottom-8ex rounded-40ex px-12ex py-4ex" data-gtm-click="全焦段4K高清视频" data-gtm-label="3X+重播">
                                                                <div class="!relative !pc:w-20px !mo:w-16px !mo:h-16px !pad:w-16px !pc:h-20px !pad:h-16px"><img class="w-full h-full" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-recoding-Refresh-simple-1.svg"></div>
                                                                <div class="font-14 w-28px h-22px pad:w-24px pad:h-20px mo:w-24px mo:h-20px ml-2px !pad:text-12px !pad:leading-[167%] !tracking-[0.5px] !mo:text-12px !mo:leading-[167%]">重播</div>
                                                            </div>
                                                        </div>
                                                        <div class="text-[#000] z-100 absolute px-28rpx py-10rpx py-8ex px-20ex py-[8apx] px-[19apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] left-26rpx bottom-16rpx left-8ex bottom-12ex left-[16apx] bottom-[16apx] font-18 font-16m">3X</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="w-screen relative w-full z-2 bg-[#fff] overflow-hidden" id="section-light" ani-light="">
                                            <div ani-leave-1="" style="transform-origin: 50% 100%; transform: scale(1); border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;">
                                                <div class="func-show relative text-[#fff] bg-[#000000] z-2" data-gtm-view="Part22: 动态超光影屏" style="transform: translateY(0px);">
                                                    <div class="wrapper w-full relative z-2 pt-274rpx pb-274rpx pt-166ex pb-250ex pb-[155apx] pt-[135apx]">
                                                        <div class="title w-322ex font-68 font-48m w-522rpx w-[368apx] whitespace-nowrap mx-auto font-b z-2" style="transform: translateY(-86.72px);">动态超光影屏<br>光通透，影深邃</div>
                                                    </div>
                                                    <div class="phone-img w-1140rpx h-720rpx w-370ex h-230ex w-[600apx] h-[353apx] -ml-10ex mx-auto" data-gtm-view="Part23: 巅峰亮度">
                                                        <div class="scale-phone w-full h-full" style="transform: scale(2.7);"><img class="w-full h-full object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-1-1-98.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-1-d7111-98.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-2-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-3-1.png.webp"></div>
                                                    </div>
                                                    <div class="brief-intro mt-52rpx pc:w-971rpx mx-auto pb-87rpx mt-20ex w-328ex pb-48ex mt-[41apx] w-[641apx] pb-[42apx]">
                                                        <div class="flex light-title mo:flex-col">
                                                            <div class="font-48 font-30m title flex-1 font-b w-[148apx]">巅峰亮度，标点都醒目</div>
                                                            <div class="font-18 font-16m desc w-[455apx] pc:w-397rpx ml-24rpx mt-18ex  ml-[38apx]">Find X 系列迄今最亮的屏幕，很可能也是行业的新纪录。峰值亮度高达 2500 尼特<sup>2</sup>，耀眼的日光下，也能看得清晰。高动态显示再创新高，画面栩栩如生，仿佛正要冲破屏幕延伸向真实世界。</div>
                                                        </div>
                                                        <div class="pc-only size-change">
                                                            <div class="flex chang1 w-976rpx mt-[40apx] w-[620apx] mt-40rpx mt-20ex mt-[18apx] mo:flex-wrap justify-between">
                                                                <div class="card card0 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-122rpx w-[95apx]">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">10.7</div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">亿色显示</div>
                                                                </div>
                                                                <div class="card card1 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-122rpx w-[85apx]">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">P3</div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">广色域</div>
                                                                </div>
                                                                <div class="card card2 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-142rpx w-[120apx]">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">AI</div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">仿生亮度调节</div>
                                                                </div>
                                                                <div class="card card3 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-176rpx w-[143apx] w-162ex mo:mt-4ex">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">1~120Hz</div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">自由动态刷新率</div>
                                                                </div>
                                                                <div class="card card4 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-197rpx w-[121apx] w-162ex mo:mt-4ex">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">高频 PWM </div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">调光<sup>2</sup></div>
                                                                </div>
                                                                <div class="card card5 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-177rpx w-[104apx] w-162ex mo:mt-4ex">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">2500nit<sup>2</sup></div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">峰值亮度</div>
                                                                </div>
                                                            </div>
                                                            <div class="flex chang2 w-976rpx mt-8rpx mt-4ex mo:flex-wrap justify-between mt-[4apx]">
                                                                <div class="card card0 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-251rpx w-[137apx]">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">3168 × 1440</div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">分辨率</div>
                                                                </div>
                                                                <div class="card card1 bg-[#201F1C] rounded-8rpx rounded-4ex rounded-[4apx] px-20rpx py-27rpx p-10ex p-[10apx] flex flex-col justify-center w-280rpx w-[85apx]">
                                                                    <div class="t font-30-1 font-32 font-b font-20m !pad:text-20px !pad:leading-[140%] !pad:tracking-[0] !break-all">DisplayMate A+</div>
                                                                    <div class="d font-16 mt-2rpx font-14m mt-14ex mt-[17apx]">评级</div>
                                                                </div>
                                                                <div class="card font-30-1 card-for-1 w-200rpx px-20rpx font-32 bg-[#201F1C] rounded-8rpx p-10ex rounded-4ex rounded-[4apx] py-27rpx flex items-center">
                                                                    <div>多亮度<br>色彩校准<sup>6</sup></div>
                                                                </div>
                                                                <div class="card card-for-2 flex pl-20rpx items-center bg-[#201F1C] rounded-8rpx w-221rpx p-10ex rounded-4ex w-162ex w-[152apx] rounded-[4apx]  pl-[10apx]"><img class="w-160rpx w-106ex w-[115apx] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-card-img-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-card-img-d7111.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-card-img-mo-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-card-img-1.png.webp"></div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="relative pt-94rpx pb-102rpx pt-96ex pb-54ex pt-[84apx] pb-[48apx] w-full bg-[#FAFAFA] flex flex-col overflow-hidden z-1 pc:-mt-200rpx -mt-100ex -mt-[200apx]" ani-light-scale="" data-gtm-view="Part24: ProXDR显示">
                                                    <div class="img-wrapper w-957rpx h-448rpx w-[498apx] h-[233apx] w-327ex h-153exrelative mx-auto z-2">
                                                        <div class="scale-img w-full h-full" style="transform: scale(2.3) translateZ(0px);">
                                                            <div class="img-change w-full h-full object-cover relative"><img class="open-close absolute w-full h-full object-cover top-0 left-0" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-open-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-open-d7111.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-mo-open-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-open-1.png.webp"><img class="open-close w-full h-full object-cover top-0 left-0" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-close-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-close-d7111.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-mo-close-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-light-close-1.png.webp"></div>
                                                        </div>
                                                    </div>
                                                    <div class="relative flex mt-12rpx mt-[10apx] mt-16ex pl-16ex z-1">
                                                        <div class="toggle-button cursor-pointer bg-[#000] bg-opacity-20 rounded-60rpx rounded-[60apx] p-2ex  p-[2apx] p-2rpx rounded-60ex pc:mx-auto pad:mx-auto">
                                                            <div class="toggle relative flex">
                                                                <div class="child active relative z-2 px-12rpx font-12m py-3rpx px-[12apx] py-[3apx] px-12ex py-3ex rounded-24rpx rounded-[24apx] text-center font-12 flex items-center justify-center" data-gtm-click="ProXDR显示" data-gtm-label="开">开</div>
                                                                <div class="child relative z-2 px-10rpx font-12m py-5rpx px-[12apx] py-[5apx] px-12ex py-5ex rounded-24rpx rounded-[24apx] text-center font-12 flex items-center justify-center" data-gtm-click="ProXDR显示" data-gtm-label="关">关</div>
                                                                <div class="mask absolute z-1 left-0 top-0 rounded-24rpx rounded-[24apx] rounded-24ex bg-[#000] h-full" style="width: 34px;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="content mt-30rpx mt-30ex mt-[24apx] pc:text-center pc:w-742rpx mo:w-full w-[597apx] px-16ex mx-auto">
                                                        <div class="t font-48 font-b font-30m">ProXDR 显示<br>在屏幕上重建光影空间</div>
                                                        <div class="d font-18 font-16m mt-18ex mt-24rpx mt-[18apx]">光子矩阵显示技术通过采集超 1200 万个光子信息点，准确还原画面中每个像素的光影分布，实现最高 8 倍的亮度提升。暗得深邃，亮得通透，层次分明，真实临境。</div>
                                                    </div>
                                                </div>
                                                <div class="dark-mask" style="border-bottom-right-radius: 0px; border-bottom-left-radius: 0px; opacity: 0;"></div>
                                            </div>
                                        </div>
                                        <div class="w-screen relative bg-[#F6F6F6] z-2 -mt-1rpx" id="section-performance" ani-performance="">
                                            <div class="mo-hidden">
                                                <div class="stick-wrapper pc-only relative w-100vw h-[calc(var(--content-height)+200vh)] bg-[#fff]">
                                                    <div class="sticky w-full top-[calc(var(--nav-height))] h-[var(--content-height)] overflow-hidden">
                                                        <div class="performance-show w-100vw h-full relative">
                                                            <div class="text-wrapper absolute left-1/2 w-522rpx transform  -translate-x-1/2 top-340rpx">
                                                                <div class="w-full h-full flex justify-center">
                                                                    <div class="text font-68 whitespace-nowrap text-opacity-95 font-b font-30m" style="transform: translateZ(0px) translateY(0px) translateX(0px);">领先旗舰性能<br>强大，高效，稳定</div>
                                                                </div>
                                                            </div>
                                                            <div class="phone-wrapper absolute left-1/2 transform translate-z-0 -translate-x-1/2 top-390rpx w-748rpx h-777rpx">
                                                                <div class="phone w-full h-full" style="transform: translateZ(0px) translateY(0px) scale(1);">
                                                                    <div class="phone-trans w-full h-full" style="transform: translateZ(0px) translateY(0px);"><img class="w-full h-full object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-inner-show-1-98.jpg.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-inner-show-d7111-98.jpg.webp"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="-mt-220vh">
                                                    <div class="relative w-100vw h-100vh">
                                                        <div class="func-wrapper absolute translate-z-0 left-[calc(50%-95rpx)] left-[calc(50%-85apx)] top-1/2 transform -translate-y-1/2 ">
                                                            <div class="show-trans bg-[#FFFFFF] p-28rpx rounded-8rpx p-[16apx] rounded-[4apx]">
                                                                <div class="t font-48 font-b !pad:text-30px !pad:leading-[127%]">第二代骁龙 8 移动平台<br>新世代旗舰性能</div>
                                                                <div class="mt-130rpx mt-[31apx]">
                                                                    <div>
                                                                        <div class="function-show flex mo:w-1px mo:overflow-hidden">
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-158rpx w-294ex w-[101apx] px-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">4nm</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">先进制程</div>
                                                                            </div>
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-168rpx w-145ex w-[101apx] pl-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">35%</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">CPU 性能提升<sup>7</sup></div>
                                                                            </div>
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-168rpx w-145ex w-[101apx] pl-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">40%</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">CPU 能效提升<sup>7</sup></div>
                                                                            </div>
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-179rpx w-145ex w-[101apx] px-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">UFS 4.0</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">全新高速闪存</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="function-show flex mt-8rpx mt-[4apx]">
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-223rpx w-145ex w-[133apx] pl-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">25%</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">GPU 性能提升<sup>7</sup></div>
                                                                            </div>
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-229rpx w-145ex w-[137apx] pl-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">45%</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">GPU 能效提升<sup>7</sup></div>
                                                                            </div>
                                                                            <div class="function !break-all py-20rpx py-10ex pl-10ex bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] py-[10apx] pl-[10apx] flex flex-col justify-center w-229rpx w-145ex w-[137apx] px-18rpx">
                                                                                <div class="font-36 font-20m t text-opacity-95 font-b !pad:text-20px !pad:leding-[140%]">LPDDR5X</div>
                                                                                <div class="font-16 font-12m d mt-12rpx mt-18ex mt-[28apx] !-tracking-[0.0005em] !pad:text-12px !pad:leading-[167%]">疾速数据传输</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="content-wrapper relative pt-144rpx pt-[48apx] bg-[#F6F6F6]">
                                                        <div class="container w-1312rpx w-[720apx] mx-auto">
                                                            <div class="flex child justify-between fade-in" ani-fade-in="" data-gtm-view="Part27: 多维隔热HyperBoost稳帧与超算平台">
                                                                <div class="left relative bg-[#FFFFFF] w-613rpx w-[358apx] p-28rpx py-[20apx] rounded-8rpx rounded-[4apx]">
                                                                    <div class="content w-full text-opacity-95 pl-16ex px-[16apx] undefined">
                                                                        <div class="t font-32 font-b font-24m">够冷，才够劲</div>
                                                                        <div class="d font-18 font-12m mt-12rpx mt-10ex mt-[10apx] w-557rpx">全新高导热石墨，超大面积真空腔均热板，配合首次引入的多维定向隔热技术，让冷静由内而外。</div>
                                                                    </div>
                                                                    <div class="mt-28rpx mt-[13apx] pl-62rpx pl-[27apx]"><img class="w-417rpx h-460rpx w-[282apx] h-[311apx]" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-1-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-1-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-1-pad-1.png.webp"></div>
                                                                    <div class="function-show absolute w-557rpx w-[324apx] bottom-28rpx left-28rpx bottom-[20apx] left-[16apx] flex justify-between">
                                                                        <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-274rpx w-145ex w-[158apx]">
                                                                            <div class="font-32 font-20m t-f t0 text-opacity-95 font-b !pad:-tracking-[0.5px]">36%</div>
                                                                            <div class="font-14 font-12m mt-12rpx mt-18ex mt-[28apx]">石墨导热系数提升</div>
                                                                        </div>
                                                                        <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-274rpx w-145ex ml-4ex w-[162apx]">
                                                                            <div class="font-32 font-20m t-f t1 text-opacity-95 font-b !pad:-tracking-[0.5px]">24%<sup>9<sup></sup></sup></div>
                                                                            <div class="font-14 font-12m mt-12rpx mt-18ex mt-[28apx]">真空腔均热板面积提升</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="right flex-col w-691rpx w-[358apx] max-w-100vw">
                                                                    <div class="relative bg-[#fff] p-28rpx py-[20apx] rounded-8rpx rounded-[4apx]">
                                                                        <div class="content w-full text-opacity-95 pl-16ex px-[16apx] undefined">
                                                                            <div class="t font-32 font-b font-24m">ColorOS 超算平台<br>颠覆式性能突破</div>
                                                                            <div class="d font-18 font-12m mt-12rpx mt-10ex mt-[10apx] w-250rpx whitespace-nowrap">极致能效，久用流畅。</div>
                                                                        </div>
                                                                        <div class="absolute w-283rpx h-215rpx w-[130apx] h-[180apx] top-5rpx right-52rpx top-[0apx] right-[0apx]">
                                                                            <div class="w-full h-full"><img class="w-full h-full object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-4-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-4-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-4-pad-1.png.webp"></div>
                                                                        </div>
                                                                        <div class="function-show pl-[16apx] mt-59rpx mt-[51apx] flex justify-between w-[339apx]">
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-204rpx w-294ex w-[105apx]">
                                                                                <div class="font-32 font-20m t-f t0 text-opacity-95 font-b !pad:-tracking-[0.5px]">16 倍<sup>8<sup></sup></sup></div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">内存瞬时访问性能提升</div>
                                                                            </div>
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-204rpx w-145ex w-[105apx]">
                                                                                <div class="font-32 font-20m t-f t1 text-opacity-95 font-b !pad:-tracking-[0.5px]">50%</div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">重载场景流畅性提升</div>
                                                                            </div>
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-212rpx w-145ex w-[105apx]">
                                                                                <div class="font-32 font-20m t-f t2 text-opacity-95 font-b !pad:-tracking-[0.5px]">48 个月<sup>8</sup></div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">久用流畅</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="relative bg-[#fff] pt-28rpx pt-[20apx] rounded-8rpx rounded-[4apx] mt-8rpx mt-[4apx] overflow-hidden">
                                                                        <div class="px-28rpx">
                                                                            <div class="content w-full text-opacity-95 pl-16ex px-[16apx] flex pc:justify-between pad:flex-col">
                                                                                <div class="t font-32 font-b font-24m">稳定畅快<br class="pad-hidden">电竞级游戏体验</div>
                                                                                <div class="d font-18 font-12m mt-[10apx] w-286rpx pc:ml-28rpx">HyperBoost 全链路游戏稳帧技术，充分释放强大性能。一直稳，一直赢，轻松享受电竞级游戏体验。</div>
                                                                            </div>
                                                                            <div class="img-wrapper w-full h-218rpx w-[360apx] h-[131apx] mt-[12apx] mt-18rpx" img-flow="" data-scale="1" data-offset="-8%"><img class="w-full h-[110%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-2-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-2-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-2-pad-1.png.webp" style="transform: translateY(-8%) scale(1);"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="flex child justify-between mt-80rpx mt-[48apx] fade-in" ani-fade-in="" data-gtm-view="Part28: 快充与智慧通信">
                                                                <div class="left relative w-613rpx w-[358apx] bg-[#fff] p-28rpx py-[20apx] rounded-8rpx rounded-[4apx]">
                                                                    <div class="content w-full text-opacity-95 pl-16ex px-[16apx] undefined">
                                                                        <div class="t font-32 font-b font-24m">疾速充，从容用</div>
                                                                        <div class="d font-18 font-12m mt-12rpx mt-10ex mt-[10apx] w-557rpx">Find X6 Pro 支持 100W 超级闪充及 50W 无线闪充，最快仅需 10 分钟，就能将电量充至 50%<sup>12</sup>。更支持超低温充电，最低 -20℃ 极端环境下仍可稳定充电。搭载电池健康引擎，一次用得久，一直用得久。</div>
                                                                    </div>
                                                                    <div class="relative z-0 mt-18rpx h-320rpx mt-[6apx] pl-83rpx pl-[20apx] h-[247apx]"><img class="w-381rpx w-[293apx] h-full object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-3-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-3-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-3-pad-1.png.webp"></div>
                                                                    <div class="function-show absolute z-1 flex justify-between bottom-28rpx left-28rpx bottom-[20apx] left-[16apx] z-2">
                                                                        <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-274rpx w-149ex w-[162apx]">
                                                                            <div class="font-32 font-20m t-f t0 text-opacity-95 font-b !pad:-tracking-[0.5px]">5000mAh<sup>10</sup></div>
                                                                            <div class="font-14 font-12m mt-12rpx mt-18ex mt-[28apx]">大电池</div>
                                                                        </div>
                                                                        <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-274rpx w-149ex w-[162apx]">
                                                                            <div class="font-32 font-20m t-f t1 text-opacity-95 font-b !pad:-tracking-[0.5px]">1600 次<sup>11</sup></div>
                                                                            <div class="font-14 font-12m mt-12rpx mt-18ex mt-[28apx]">充放电循环</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="right relative w-691rpx w-[358apx] bg-[#fff] p-28rpx py-[20apx] rounded-8rpx rounded-[4apx]">
                                                                    <div class="content w-full text-opacity-95 pl-16ex px-[16apx]">
                                                                        <div class="t font-32 font-b font-24m">智慧通信，时刻畅联世界</div>
                                                                    </div>
                                                                    <div class="w-full pl-[16apx] mb-8rpx mb-[4apx] mt-[29apx] mt-19rpx">
                                                                        <div class="w-[322apx] w-635rpx bg-[#F5F4F0] rounded-[4apx] rounded-8rpx h-295rpx h-[197apx] overflow-hidden">
                                                                            <div class="w-570rpx h-269rpx h-[178apx] w-[302apx]"><img class="w-full h-full object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-5-1.jpg.webp?v=230321" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-5-d7111.jpg.webp?v=230321" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-performance-5-pad-1.jpg.webp?v=230321"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="w-635rpx mt-[4apx] w-[338apx] pl-[16apx]">
                                                                        <div class="function-show flex">
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-315rpx w-156ex w-[162apx]">
                                                                                <div class="font-32 font-20m t-f t0 text-opacity-95 font-b !pad:-tracking-[0.5px]">六网协同</div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">多网无感切换，一直在线</div>
                                                                            </div>
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-312rpx w-136ex w-[162apx]">
                                                                                <div class="font-32 font-20m t-f t1 text-opacity-95 font-b !pad:-tracking-[0.5px]">三网并发</div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">7.5Gbps 疾速下载， 比快更快<sup>13</sup></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="function-show changeText flex mt-8rpx mt-[4apx]">
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-315rpx w-[162apx]">
                                                                                <div class="font-32 font-20m t-f t0 text-opacity-95 font-b !pad:-tracking-[0.5px]">双 5G 通信共享<sup>13</sup></div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">手机平板无缝流转，更快更省电</div>
                                                                            </div>
                                                                            <div class="function flex flex-col justify-between py-20rpx pl-20rpx py-10ex p-[10apx] pl-10ex pl-[10apx] bg-[#F5F4F0] rounded-8rpx rounded-4ex rounded-[4apx] w-312rpx w-[162apx]">
                                                                                <div class="font-32 font-20m t-f t1 text-opacity-95 font-b !pad:-tracking-[0.5px]">全新 Wi-Fi 7<sup>14</sup></div>
                                                                                <div class="font-14 font-12m mt-12rpx mt-18ex mt-[8apx]">更低时延，更强抗干扰</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="w-screen relative z-3 bg-[#F6F6F6]" id="section-color" ani-color="">
                                            <div class="w-full py-182rpx py-80ex py-[148apx] flex flex-col pc:items-center mo:pl-16ex mo:pr-31ex" data-gtm-view="Part29: ColorOS13">
                                                <div class="title font-64 font-b font-48m">ColorOS 13.1<br> 高效体验<span class="mo-hidden">，</span>行云流水</div><a class="mt-24rpx mt-12ex mt-[12apx] rounded-36rpx rounded-[36apx] inline-block font-14 px-20rpx py-9rpx py-9ex px-20ex px-[20apx] py-[9apx] rounded-36ex font-14m bg-[#000] text-[#fff] mo:w-[calc(70px+40ex)]" data-gtm-click="ColorOS13" data-gtm-label="进一步了解" href="https://www.coloros.com/feature/coloros13" target="_blank">
                                                    <div class="btn">进一步了解</div>
                                                </a>
                                            </div>
                                            <div class="mo-hidden">
                                                <div class="wrapper w-1312rpx w-[720apx] pc:mx-auto px-16ex">
                                                    <div class="flex pc:justify-between fade-in" ani-fade-in="" data-gtm-view="Part30: 智慧提醒与手机防丢失">
                                                        <div class="card-wrapper relative w-663rpx w-[358apx]">
                                                            <div class="content w-full text-opacity-95 pt-32rpx pl-24rpx px-16ex px-[16apx] pt-[24apx]">
                                                                <div class="t font-32 font-b font-24m ">ColorOS 智慧提醒<br>想到你前头</div>
                                                                <div class="d mt-12rpx mt-8ex mt-[8apx] font-18 w-608rpx font-12m">机票订好，全流程泛在服务已就位。什么时候打车去机场？旅途中要看哪部电影？登机口在哪？行李在几号转盘？全场景智能服务推荐，想到你前头去了。</div>
                                                            </div>
                                                            <div class="h-305rpx mt-34rpx mt-[14apx] pl-[229rpx] h-[191apx] pl-[104apx]" img-flow="" data-scale="1" data-offset="-8%"><img class="w-206rpx w-[150apx] h-[110%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-1-1.jpg.webp?v=230321" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-1-d7111.jpg.webp?v=230321" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-1-pad-1.jpg.webp?v=230321" style="transform: translateY(-8%) scale(1);"></div>
                                                        </div>
                                                        <div class="card-wrapper color-card-change relative w-641rpx w-[358apx]">
                                                            <div class="content w-full text-opacity-95 pt-32rpx pl-24rpx px-16ex px-[16apx] pt-[24apx]">
                                                                <div class="t font-32 font-b font-24m ">手机查找<br>离线轻松定位，数据一键锁定</div>
                                                                <div class="d mt-12rpx mt-8ex mt-[8apx] font-18 w-575rpx font-12m">即便设备离线，仍能一键定位遗失的手机<sup>15</sup>。启动“远程锁定”，数据安全更放心。</div>
                                                            </div>
                                                            <div class="absolute w-206rpx w-[150apx] h-[191apx] h-305rpx left-218rpx bottom-0 left-[104apx]">
                                                                <div class="w-full h-full" img-flow="" data-scale="1" data-offset="-8%"><img class="w-full h-[110%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-2-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-2-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-2-pad-1.png.webp" style="transform: translateY(-8%) scale(1);"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex pc:justify-between fade-in" ani-fade-in="" data-gtm-view="Part31: 因素保护与端端互联">
                                                        <div class="card-wrapper relative w-663rpx w-[358apx] mt-8rpx mt-[4apx]" change-text="">
                                                            <div class="content w-full text-opacity-95 pt-32rpx pl-24rpx px-16ex px-[16apx] pt-[24apx]">
                                                                <div class="t font-32 font-b font-24m w-633rpx !break-all">逆声场通话隐私保护<span class="pc-only">，</span>通话更安心</div>
                                                                <div class="d mt-12rpx mt-8ex mt-[8apx] font-18 w-600rpx font-12m">双振膜主动式通话隐私保护技术加持，针对通话中的听筒漏音，通过两个音频单元间的声波抵消，实现指向性的音频隔绝。对你说的话，只要你听到。工作中，电梯里，聚会时，来电从容应对，尴尬遁形，隐私无忧。</div>
                                                            </div>
                                                            <div class="mt-36rpx mt-[18apx] w-full h-338rpx h-[245apx] " img-flow="" data-scale="1" data-offset="-10%"><img class="w-full h-[110%] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-3-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-3-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-3-pad-1.png.webp" style="transform: translateY(-10%) scale(1);"></div>
                                                            <div class="content-card absolute bottom-26rpx bottom-[21apx] left-24rpx left-[16apx] p-20rpx p-[10apx] pr-[33apx] bg-[#F5F4F0] rounded-8rpx rounded-[4apx]">
                                                                <div class="font-24 font-b !pad:text-20px !pad:leading-[140%]">双振膜自适应<br>声场扬声器</div>
                                                                <div class="font-14 mt-12rpx mt-[15apx] pad:font-20 !pad:text-12px !pad:leading-[167%]">最高 3.5 倍声音隔绝效果<sup>16</sup></div>
                                                            </div>
                                                        </div>
                                                        <div class="card-wrapper relative w-641rpx w-[358apx] mt-8rpx mt-[4apx] overflow-hidden">
                                                            <div class="absolute w-full top-0 left-0 z-2">
                                                                <div class="content w-full text-opacity-95 pt-32rpx pl-24rpx px-16ex px-[16apx] pt-[24apx]">
                                                                    <div class="t font-32 font-b font-24m ">ColorOS 智慧跨端<br>端端互联，行云流水</div>
                                                                    <div class="d mt-12rpx mt-8ex mt-[8apx] font-18 w-559rpx font-12m">设备配齐，体验也得到位。无论是在进行中的线上会议，还是影音体验，不同设备间都能无感接续，如同多设备合一般便捷<sup>17</sup>。</div>
                                                                </div>
                                                            </div>
                                                            <div class="absolute w-full h-full"><img class="w-full h-full object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-4-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-4-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-4-pad-1.png.webp"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bottom w-1312rpx w-[720apx] mx-auto mt-[4apx] mt-8rpx flex justify-between pb-64rpx pb-[64apx] fade-in" ani-fade-in="">
                                                    <div class="left relative w-663rpx w-[358apx] bg-[#fff] pt-32rpx pt-[24apx] pl-24rpx pl-[16apx] pb-[14apx] pb-37rpx rounded-8rpx rounded-[4apx] overflow-hidden">
                                                        <div class="t font-28 font-b font-24m">10 项业界权威隐私保护认证</div><img class="w-621rpx h-45rpx w-[302apx] h-[96apx] mt-41rpx mt-[13apx] object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-icon-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-icon-d7111.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-icon-pad-1.png.webp">
                                                    </div>
                                                    <div class="right pc:relative w-642rpx w-[358apx] bg-[#fff] pt-32rpx pt-[16apx] pc:pb-52rpx pb-[52apx] pl-24rpx px-[16apx] rounded-8rpx rounded-[4apx] overflow-hidden">
                                                        <div class="content">
                                                            <div class="t font-28 font-b font-24m">全链路支付保护<br>金融级安全守护</div>
                                                            <div class="d mt-12rpx mt-8ex mt-[8apx] font-16 w-579rpx font-12m">联合蚂蚁安全实验室，共建金融级恶意代码识别引擎，每一笔，全链路守护。</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="div w-full bg-[#f6f6f6] pb-64rpx mo:py-40px pad:pb-48px" data-gtm-view="Part32: 2023超影像大赛">
                                            <div class="backup w-1312rpx rounded-8rpx w-[720apx] rounded-4px w-328ex overflow-hidden transform translate-z-0 w-full h-480rpx m-auto h-[300apx] h-540ex relative flex items-center justify-start mo:justify-center mo:items-start mo:pt-80px pl-102rpx pl-[48apx]"><img class="bg w-full h-full object-cover absolute top-0 left-0 z-0" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-bg-1.jpg.webp?v=230321" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-bg-d7111.jpg.webp?v=230321" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-bg_mo-1.jpg.webp?v=230321" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-bg_pad-1.jpg.webp?v=230321">
                                                <div class="content relative z-1 pt-20rpx"><img class="logo h-166rpx w-403rpx pad:w-[221apx] pad:h-[91apx] object-cover h-107ex" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-logo-1.png.webp" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-logo-d7111.png.webp" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-logo-1.png.webp" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-footer-logo-1.png.webp">

                                                    <a class="btn font-14 px-[20px] py-[9px] mt-28rpx mt-[18apx] font-14m px-24rpx py-11rpx bg-white w-max mo:m-auto rounded-full block font-b" data-gtm-click="2023超影像大赛" href="https://imagine-if.oppo.com/cn/" target="_blank">进一步了解</a>

                                                    <div class="sol mo:text-center mt-86rpx pad:mt-[39apx] mo:mb-18px mo:mt-15px pad:mt-12px pad:mb-18px text-white text-opacity-95 font-14m !tracking-[0.4em] font-14 font-b mo-hidden">2023年4月17日 全球开启</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-[#FFFFFF] w-full pt-80rpx pb-128rpx mo:py-96ex mo:px-16ex pad:py-[96apx] box-border" id="section-remarks">
                                            <div class="content pc:w-1312rpx pad:w-[720apx] mo:w-full mx-auto mo:mx-0 text-black text-opacity-55" id="Disclaimer">
                                                <div class="font-400 text-12px leading-[170%] -tracking-0.05px">注释：</div>
                                                <ul>
                                                    <li class="text-left font-12 font-12m">1. 借助高像素传感器中央的 1200 万有效像素，实现光学品质的 6 倍变焦成像。</li>
                                                    <li class="text-left font-12 font-12m">2. 2500 尼特为局部峰值亮度；显示 HDR 内容时，屏幕最高亮度为 2200 尼特；全局峰值亮度 1500 尼特。另 OPPO Find X6 Pro 支持 1440Hz 高频 PWM 调光。</li>
                                                    <li class="text-left font-12 font-12m">3. Find X6 Pro 在正常使用状态下可防溅、防水、防尘。在受控实验室条件下，经测试，其效果在 GB/T 4208-2017（国内）标准下达到 IP68 级别。防溅、防水、防尘功能可能会因日常使用损耗而下降。IP68 级防水条件为：①无流动清水，水深 1.5 米；②试验时间 30 分钟；③水温与产品温差不大于 5 摄氏度。请勿在潮湿状态下为手机充电，生活场景与实验环境存在差异，日常使用请勿将手机完全浸入水中，或接触海水、氯化水等溶液或饮品等液体中。由于浸入液体而导致的损坏不在保修范围内。</li>
                                                    <li class="text-left font-12 font-12m">4. 悬浮棱镜防抖机构中的棱镜在非工作状态下存在一定位移属于正常现象。</li>
                                                    <li class="text-left font-12 font-12m">5. 双耳录音 2.0：与杜比实验室联合研发，在手机视频录制中，配合 OPPO Enco X2 耳机，实现双耳机真立体声收音。提升抗风噪能力和音乐现场录制能力，主体声音清晰洪亮，无损记录声场空间感。视频回放时，带来身临其境的空间音频双耳渲染效果。</li>
                                                    <li class="text-left font-12 font-12m">6. 多亮度色彩校准：基于室内（100nit）、室外（800nit）亮度分别对屏幕颜色进行校准，确保色彩准确性和一致性。</li>
                                                    <li class="text-left font-12 font-12m">7. 数据来源于高通官方，对比上一代骁龙 8 移动平台 。</li>
                                                    <li class="text-left font-12 font-12m">8. 瞬时带宽技术需通过后续版本 OTA 支持；48 个月久用流畅不等于绝对不卡顿，因网络环境、个人使用习惯等因素不同，实际体验可能有所差异。</li>
                                                    <li class="text-left font-12 font-12m">9. 散热能力数据源于官方实验室测算；相对于 OPPO Find X5 Pro，OPPO Find X6 Pro 的真空腔均热板面积增加了 24%。</li>
                                                    <li class="text-left font-12 font-12m">10. Find X6 Pro 采用双电芯串联，电池容量典型值为 2 x 2500mAh（2500mAh 为单电池容量典型值，额定值为 2440mAh）。</li>
                                                    <li class="text-left font-12 font-12m">11. 实际测试电池经 1600 次完整循环（满充满放）后剩余容量大于 80%，数据来源于 OPPO 实验室，可能会因为测试设备、环境等不同而产生差异。因个人实际使用方式和习惯的不同，亦可能产生差异。</li>
                                                    <li class="text-left font-12 font-12m">12. 100W 超级闪充指充电器的输出功率为 100W，实际功率或因环境不同而有所差异；充电时长数据来自 OPPO 实验室，测试机型：Find X6 Pro，有线充电平均 10 分钟由 1% 充至 50%。测试环境：电池初始温度 25±1°C，环境温度 25±1°C。测试条件：在手机电量为 1% 的情况下，关闭除通话以外的其他手机功能和服务，显示屏息屏，使用标配电源适配器充电。实际充电速度可能因设备使用情况、充电条件及其他因素的不同而有所不同；低温充电环境测试数据来源于 OPPO 实验室，手机预热到 -10℃ 以上再进行充电。测试环境：环境及手机温度 - 20℃±3℃，测试环境为密闭无风环境，测试条件：息屏状态下，搭配产品官方标配充电器 + 数据线充电。实际数据可能因用户使用环境、电池损耗状态等因素存在差异。</li>
                                                    <li class="text-left font-12 font-12m">13. 网络速率数值来源于 OPPO 实验室测算，实际数据可能因用户使用的网络环境存在差异；双 5G 通信共享需与 OPPO Pad 2 配合使用，OPPO Pad 2 需单独购买。</li>
                                                    <li class="text-left font-12 font-12m">14. Wi-Fi 7 需 OTA 升级系统版本开启。</li>
                                                    <li class="text-left font-12 font-12m">15. 设备需登陆 OPPO 账号，并曾插入 SIM 卡使用，在有电且开机的状态下功能方可生效。</li>
                                                    <li class="text-left font-12 font-12m">16. 音频能力数据源于官方实验室测算；相对于 OPPO Find X5 Pro，手机背面防漏音能力提升 3.5 倍；通话隐私保护能在一定程度上减少听筒漏音，非完全隔音，漏音听感与环境嘈杂程度有关，请以实际体验为准。</li>
                                                    <li class="text-left font-12 font-12m">17. ColorOS 智慧跨端需与 OPPO 智能设备配合使用。</li>
                                                    <li class="text-left font-12 font-12m">*手机实际可用容量会由于诸多因素而减少并有所差异，包括但不限于标准系统配置会占用的存储容量 (其中包括 ColorOS 系统及其最新功能，以及各部分可删除的应用)，内存拓展功能默认开启会占用 4GB 空间，可在设置中关闭；最终可使用存储容量依软件版本、设置和机型的不同而有所差异。</li>
                                                    <li class="text-left font-12 font-12m">*摄像头像素数值为传感器硬件规格，实际成片因模式不同而存在差异，请以实际为准。</li>
                                                    <li class="text-left font-12 font-12m">*如无特殊备注，以上页面中所示数据均为 OPPO 实验室数据，仅供参考。实际数据受使用环境、设备状况、软件版本等因素影响将略有差异，请以实际数据为准。</li>
                                                    <li class="text-left font-12 font-12m">*如无特殊备注，以上页面中所示产品界面内容（包括但不限于 UI、背景）仅作功能展示，各个功能分属不同软件版本，不同软件版本的上线时间与区域有所差异，请以实际版本功能及界面为准。</li>
                                                </ul>
                                                <div class="RoHS mt-15px flex items-center"><img class="w-68px h-44px object-cover" data-src-1440="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-RoHs.png" data-src-1024="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-RoHs.png" data-src-360="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-RoHs.png" data-src-768="/content/dam/oppo/product-asset-library/find/find-x6-series/find-x6-pro/v1/assets/images-color-RoHs.png">
                                                    <div class="mt-6px ml-10px text-12px leading-20px tracking-0.5px">本产品通过中国 RoHS 合格评定</div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </section>

        <span>

            <section class="light-theme">
                <footer id="footer">
                    <Foot></Foot>
                </footer>
            </section>
        </span>

    </div>

</body>
</template>

    <script>
    import {
        productInfo
    } from '@/request/api'
    import Head from '../../components/heads.vue'
    import Foot from '../../components/footer.vue'
    export default {
        components: {
            Head,
            Foot
        },
        data() {
            return {
                info: null,

                baseUrl: "https://www.inrunonline.com/api/sys/common/static/"
            }
        },
        created() {
            let id = this.$route.params.id
            if(id){
                productInfo({id:id}).then((res) => {
                    this.info = this.md(res.result.details);
                })
            }
        },
    }
    </script>

<style>
.content > p{
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
    flex-direction: column;
}
.content >p > img {
    max-width: 790px;
    height: auto;
    image-rendering:-moz-crisp-edges;
        image-rendering:-o-crisp-edges;
        image-rendering:-webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode:nearest-neighbor;
}
@media (max-width: 767.98px) {
    .content >p > img {
    max-width: 100%;
    height: auto;
    image-rendering:-moz-crisp-edges;
        image-rendering:-o-crisp-edges;
        image-rendering:-webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode:nearest-neighbor;
}
}
</style>